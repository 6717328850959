<template>
  <el-upload
      class="avatar-uploader"
      :disabled="uploadAction === ''"
      :action="uploadAction"
      :name="name"
      :data="uploadData"
      :accept="'image/*'"
      :show-file-list="false"
      :headers="headers"
      :before-upload="handleBeforeUpload"
      :on-success="handleSuccess"
      :on-error="handleError"
  >

     <el-image
      :name="photo.des"
      class="bg"
      :src="url"
      lazy
      ></el-image>
  </el-upload>
</template>

<script>
// import flatry from '@admin/utils/flatry'
import Auth from '@admin/utils/auth'

export default {
  name: 'Uploader',
  props: { photo: {} },
  data () {
    return {
      url: '',
      name: 'img',
      uploadAction: '',
      uploadData: null,
      uploadFolder: '',
      uploadSizeLimit: 1024000,
      headers: {
        'X-NAME': this.photo.name,
        'X-Access-Token': Auth.getAccessToken()
      }
    }
  },
  async mounted () {
    this.uploadAction = Auth.getHttpApiUrl() + 'setting/photo'
    this.url = Auth.getHostUrl() + '/uploads/setting/photo/' + this.photo.name + '.png?time=' + String(parseInt(new Date().getTime() / 1000))
  },
  methods: {
    handleBeforeUpload (file) {
      if (file.size > this.uploadSizeLimit) {
        this.$message({
          type: 'error',
          message: '你选择的文件大小超出上传限制',
          duration: 1500
        })

        return false
      }

      return true
    },
    handleSuccess (response) {
      if (response.success === true) {
        this.url = response.data

        this.reload()
        this.$emit('on-upload-success', this.url)
      }
    },
    handleError (error) {
      console.log(error)
      let rg = /"msg":"(.*)","data"/ig
      const msg = rg.exec(error)
      this.$message.error(msg[1] || '上传文件失败:(')
    }
  }
}
</script>
<style lang="scss">
.avatar-uploader {
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 16px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
    }

    .avatar {
        border: 1px dashed #d9d9d9;
        width: 90px;
        height: 90px;
        display: block;
        object-fit: cover;
        background-size: cover;
    }
}
</style>
