<template>
    <div>
        <div class="setting-box">
            <el-form
                v-model="model"
                ref="form"
                label-width="180px"
            >
                <h3>小程序跳转设置</h3>
                <el-form-item
                    prop="is_show"
                    label="是否开启显示入口"
                >
                    <el-radio-group v-model="model.body.is_show">
                        <el-radio :label="0">关闭</el-radio>
                        <el-radio :label="1">开启</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    prop="name"
                    label="名称"
                >
                    <el-input
                        style="width:300px"
                        type="text"
                        v-model.trim="model.body.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="图标">
                    <logo
                      :photo="uplogImg"
                    />
                </el-form-item>
                <el-form-item
                    prop="appid"
                    label="appid"
                >
                    <el-input
                        style="width:300px"
                        type="text"
                        v-model.trim="model.body.appid"
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button
                        type="primary"
                        size="medium"
                        :loading="Loading"
                        @click="formSubmit"
                    >保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import SettingService from '@admin/services/SettingService'
import flatry from '@admin/utils/flatry'
import upload from './components/Upload'
export default {
  data () {
    return {
      Loading: false,
      model: {
        type: 99,
        body: {
          is_show: 0,
          appid: '',
          icon: ''
        }
      },
      uplogImg: {
        name: 'jump-img',
        des: '图标'
      }
    }
  },
  components: {
    logo: upload
  },
  async mounted () {
    const { data } = await flatry(SettingService.edit())
    if (data.data.jump) {
      this.model = data.data.jump
    }
  },
  methods: {
    formSubmit (e) {
      this.editCreate(this.model)
    },

    async editCreate (param, success, callback) {
      const { data } = await flatry(SettingService.edit(param))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/setting/jump' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-box {
    margin-bottom: 50px;
    padding: 24px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    transition: 0.2s;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
        0 2px 4px 0 rgba(232, 237, 250, 0.5);
    h3 {
        margin-left: 180px;
        font-size: 1.06em;
    }
}
</style>
