import Vue from 'vue'

const StoreService = {
  all (query) {
    return Vue.http.get('setting/index', query)
  },

  edit (store = null) {
    const endpoint = 'setting/create'

    if (store === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, store)
  },
  // 用户前缀编号
  userNumber (param = null) {
    const endpoint = 'setting/user-number'
    if (param === null) {
      return Vue.http.get(endpoint)
    }
    return Vue.http.post(endpoint, param)
  },
  // 外卖设置数据
  getTakeOut (param = null) {
    const endpoint = 'setting/take-out'
    if (param === null) {
      return Vue.http.get(endpoint)
    }
    return Vue.http.post(endpoint, param)
  }
}

export default StoreService
